import React from "react";
import Div100vh from "react-div-100vh";
import {GatsbyImage} from "gatsby-plugin-image";

const Landing = ({image, quote, text}) => {

    return (
        <div className={"landing__outer"}>
                <div className="color-bg bg-blue left"></div>
                <div className="color-bg bg-orange right"></div>
                <div className="landing__inner">
                    <h1 className="text color-light uppercase">{text}</h1>
                    <div className="image__container">
                        <GatsbyImage image={image.asset.gatsbyImageData} alt={text} ></GatsbyImage>
                        <div className="quote__outer">
                            <div className="quote color-dark scene center italic">"{quote}"</div>
                        </div>
                    </div>
                    <div className="quote__outer">
                        <div className="quote color-dark scene center italic">"{quote}"</div>
                    </div>
                </div>
        </div>
    )
}

export default Landing;
