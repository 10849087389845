import React, {useEffect, useRef} from "react";
import Div100vh from "react-div-100vh";
import MyPortableText from "./MyPortableText";
import Arrow from "./Arrow";
import {localeLink} from "../util";
import {GatsbyImage} from "gatsby-plugin-image";

const Angebote = ({angebote, location, entdecken}) => {

    let titleRef1 = useRef();
    let titleRef2 = useRef();
    let titleRef3 = useRef();

    useEffect(() => {
        titleRef1.current.innerHTML = angebote[0]._rawTitle;
        titleRef2.current.innerHTML = angebote[1]._rawTitle;
        titleRef3.current.innerHTML = angebote[2]._rawTitle;
    })

    return (
        <div className={"angebote__outer"} id="angebot">
            <Arrow></Arrow>
            <div className="angebote__inner">
                <div className={angebote[0].pageactive ? "first between" : "first"}>
                    <div className="bg bg-orange"></div>
                    <div className="content">
                        <div className={"angebot__icon__outer"}>
                            <GatsbyImage className={"angebot__icon"} image={angebote[0].icon?.asset?.gatsbyImageData} alt={angebote[0]._rawTitle}/>
                        </div>
                        <h2 className={"color-light scene uppercase"} ref={titleRef1}>{angebote[0]._rawTitle}</h2>
                        <div className="subheadline">
                            <MyPortableText value={angebote[0]._rawSubheadline}></MyPortableText>
                        </div>
                        {angebote[0]._rawTags.map(el => {
                            return <h3 className={"color-dark uppercase"}>{el}</h3>
                        })}
                        <MyPortableText value={angebote[0]._rawTextoverview}></MyPortableText>
                    </div>
                    {angebote[0].pageactive ?
                        <div className="btn__container">
                            <a href={localeLink("/" + angebote[0].slug.current, location)} className="btn bg-orange-dark color-light uppercase hover-moveup">{entdecken}</a>
                        </div>
                        : <></>}
                </div>
                <div className={angebote[1].pageactive ? "second between" : "second"}>
                    <div className="bg bg-blue"></div>
                    <div className="content">
                        <div className={"angebot__icon__outer"}>
                            <GatsbyImage className={"angebot__icon"} image={angebote[1].icon?.asset?.gatsbyImageData} alt={angebote[1]._rawTitle}/>
                        </div>
                        <h2 className={"color-light scene uppercase"}  ref={titleRef2}></h2>
                        <div className="subheadline">
                            <MyPortableText value={angebote[1]._rawSubheadline}></MyPortableText>
                        </div>
                        {angebote[1]._rawTags.map(el => {
                            return <h3 className={"color-dark uppercase"}>{el}</h3>
                        })}
                        <MyPortableText value={angebote[1]._rawTextoverview}></MyPortableText>
                    </div>
                    {angebote[1].pageactive ?
                    <div className="btn__container">
                        <a href={localeLink("/" + angebote[1].slug.current, location)} className="btn bg-orange-dark color-light uppercase hover-moveup">{entdecken}</a>
                    </div>
                        : <></>}
                </div>
                <div className={angebote[2].pageactive ? "third between" : "third"}>
                    <div className="bg bg-orange"></div>
                    <div className="content">
                        <div className={"angebot__icon__outer"}>
                            <GatsbyImage className={"angebot__icon"} image={angebote[2].icon?.asset?.gatsbyImageData} alt={angebote[2]._rawTitle}/>
                        </div>
                        <h2 className={"color-light scene uppercase"}  ref={titleRef3}></h2>
                        <div className="subheadline">
                            <MyPortableText value={angebote[2]._rawSubheadline}></MyPortableText>
                        </div>
                        {angebote[2]._rawTags.map(el => {
                            return <h3 className={"color-dark uppercase"}>{el}</h3>
                        })}
                        <MyPortableText value={angebote[2]._rawTextoverview}></MyPortableText>
                    </div>
                    {angebote[2].pageactive ?
                    <div className="btn__container">
                        <a href={localeLink("/" + angebote[2].slug.current, location)} className="btn bg-orange-dark color-light uppercase hover-moveup">{entdecken}</a>
                    </div>
                        : <></>}
                </div>
            </div>
        </div>
    )
}

export default Angebote;

/*
<div className="color-bg bg-orange first"></div>
            <div className="color-bg bg-blue second"></div>
            <div className="color-bg bg-orange third"></div>
 */
