import * as React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import Landing from "../components/Landing";
import Angebote from "../components/Angebote";
import Kunden from "../components/Kunden";
import Vita from "../components/Vita";
import {GatsbyImage} from "gatsby-plugin-image";
import localize from "../components/localize";
import {localeLink} from "../util";


export const query = graphql`
    {
    site {
    siteMetadata {
    lang{
        _type
        en
        de
        }
        angebot{
        _type
        en
        de
        }
        kunden{
        _type
        en
        de
        }
        mehrEntdecken{
        _type
        en
        de
        }
        impressum{
        _type
        en
        de
        }
        datenschutz{
        _type
        en
        de
        }
        agbs{
        _type
        en
        de
        }
        telefon{
        _type
        en
        de
        }
        startseite{
        _type
        en
        de
        }
        download{
        _type
        en
        de
        }
    }
    }
  allSanitySettings {
    edges {
      node {
      standorte {
          street
          plz
          _rawStadt
          _rawName
        }
        telefon
        skype
        email
        linkedin
        jobsexternal
        jobsexternallink
      }
     }
     }
     allSanityNetzwerk {
    edges {
      node {
      title{
      en
      de
      _type
      }
      }
      }
      }
    }
   `;

const NotFoundPage = ({data, location}) => {

    const settings = data.allSanitySettings.edges[0].node;
    const netzwerk = data.allSanityNetzwerk.edges[0].node;

    return (
        <div id={"top"}>
            <Layout location={location} metadata={data.site.siteMetadata} settings={settings} netzwerk={netzwerk.title}>
                <SEO/>
                <div className={"landing__outer"}>
                    <div className="color-bg bg-blue left"></div>
                    <div className="color-bg bg-orange right"></div>
                    <div className="error__inner">
                        <h1 className="text color-light uppercase errorheadline">404</h1>
                        <div className="btn__container">
                            <a href={localeLink("/", location)} className="btn bg-orange-dark color-light uppercase hover-moveup">{data.site.siteMetadata.startseite}</a>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    )
}

export default localize(NotFoundPage)
