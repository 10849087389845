import React from "react";
import Div100vh from "react-div-100vh";
import MyPortableText from "./MyPortableText";
import Arrow from "./Arrow";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";


const Vita = ({subheadline, headline, quote, image, text, download, linkedin, signature}) => {

    return (
        <div className={"vita__outer"} id={"vita"}>
            <Arrow></Arrow>
            <div className="vita__inner">
                <div className="color-bg bg-blue left"></div>
                <div className="color-bg bg-orange right"></div>
                <div className="first">
                    <div className="quote__outer">
                        <div className="quote-s color-dark scene center italic">"{quote}"</div>
                    </div>
                        <GatsbyImage className={"signature__outer"} image={signature.asset.gatsbyImageData} alt={headline}></GatsbyImage>
                    <GatsbyImage className={"vitaportrait"} image={image.asset.gatsbyImageData} alt={headline}></GatsbyImage>
                </div>
                <div className="second">
                    <h4 className={"color-light uppercase ibm"}>VITA</h4>
                    <h2 className={"scene uppercase color-dark"}>{headline}</h2>
                    <h3 className={"scene uppercase color-dark"}>{subheadline}</h3>
                    <div className="text">
                        <MyPortableText value={text}></MyPortableText>
                    </div>
                    <a href={linkedin} target={"_blank"} className={"small uppercase hover-moveup"}>{download}</a>
                </div>
            </div>
        </div>
    )
}

export default Vita;
