import React from "react";
import Div100vh from "react-div-100vh";
import MyPortableText from "./MyPortableText";
import Arrow from "./Arrow";
import {Autoplay} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import "swiper/css";
import Marquee from "react-fast-marquee";
import {GatsbyImage} from "gatsby-plugin-image";


const Kunden = ({testimonials, logos, kunden, grey}) => {

    return (
        <div className={"kunden__outer bg-light color-blue"} id={"kunden-referenzen"}>
            <Arrow></Arrow>
            <div className="kunden__inner">
                <h2 className={"uppercase"}>{kunden}</h2>
                <Swiper className="mySwiper"
                        loop={true}
                        autoplay={{
                            delay: 30000,
                            disableOnInteraction: true,
                        }}
                        modules={[Autoplay]}>
                    {testimonials.map((el) => {
                        return (
                            <SwiperSlide className={"testimonial__slide"}>
                                <div className="large">
                                    <MyPortableText value={el._rawText}></MyPortableText>
                                </div>
                                <p className="small uppercase name">{el.name}</p>
                                <p className="small uppercase">{el._rawJob}</p>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>
            <Marquee gradient={false} className={grey ? "greyimages" : ""} speed={20}>
                {logos.map((el) => {
                    return <GatsbyImage image={el.asset.gatsbyImageData} alt={"Kundenlogo"}></GatsbyImage>
                })}
                {logos.map((el) => {
                    return <GatsbyImage image={el.asset.gatsbyImageData} alt={"Kundenlogo"}></GatsbyImage>
                })}
            </Marquee>
        </div>
    )
}

export default Kunden;
